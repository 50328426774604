<template>
    <div class="register-page">注册</div>
</template>

<script>
export default {
    name: "Register",
};
</script>

<style>
</style>